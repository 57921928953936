<template>
	<banner-style1 :position="9"></banner-style1>
	<div class="work-detail">
		<div class="work-detail-con c w">
			<el-breadcrumb separator=">">
				<el-breadcrumb-item :to="{ path: '/' }">
					<el-icon style="vertical-align: middle">
						<HomeFilled />
					</el-icon>首页
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{detail.name}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="card-content">
				<p class="area-name">{{detail.name}}</p>
				<div class="table table-bordered">
					<div class="table-column" v-if="detail.name">
						<span class="label">分赛区</span>
						<span class="value">{{detail.name}}</span>
					</div>
					<div class="table-column" v-if="detail.company">
						<span class="label">承办单位</span>
						<span class="value">{{detail.company}}</span>
					</div>
					<div class="table-column" v-if="detail.contacts">
						<span class="label">联系人</span>
						<span class="value">{{detail.contacts}}</span>
					</div>
					<div class="table-column" v-if="detail.mobile">
						<span class="label">联系电话</span>
						<span class="value">{{detail.mobile}}</span>
					</div>
					<div class="table-column" v-if="detail.email">
						<span class="label">联系邮箱</span>
						<span class="value">{{detail.email}}</span>
					</div>
					<div class="table-column" v-if="detail.orgName">
						<span class="label">组委会</span>
						<span class="value">{{detail.orgName}}</span>
					</div>
					<div class="table-column" v-if="detail.orgContacts">
						<span class="label">组委会联系人</span>
						<span class="value">{{detail.orgContacts}}</span>
					</div>
					<div class="table-column" v-if="detail.orgMobile">
						<span class="label">组委会联系电话</span>
						<span class="value">{{detail.orgMobile}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		HomeFilled
	} from '@element-plus/icons-vue'
	import {
		ref
	} from 'vue'
	import {
		useRoute
	} from 'vue-router';
	import {
		getAreaInfo
	} from '@/api/competition'
	const route = useRoute()
	const detail = ref('')
	console.log(route.query.id, 'route');
	getAreaInfo({
		id: route.query.id
	}).then(res => {
		console.log(res, 'res');
		detail.value = res.data
	})
</script>

<style lang="scss" scoped>
	.work-detail {
		padding-bottom: 80px;
	}

	.el-breadcrumb {
		font-size: 14px;
		padding-top: 30px;
	}

	.el-breadcrumb__separator {
		color: #000;
	}

	.card-content {
		.area-name {
			font-size: 36px;
			margin-top: 50px;
		}

		.table {
			width: 100%;
			min-width: 100%;
			background-color: #fff;
			margin-top: 10px;

			.table-column {
				display: flex;

				&:nth-child(n+2) {
					border-top: 1px solid #ddd;
				}

				&:nth-child(odd) {
					background-color: #f9f9f9;
				}

				span {
					display: inline-block;
					white-space: pre;
				}

				.label {
					width: 30%;
					padding: 8px;
				}

				.value {
					flex: 1;
					padding: 8px;
					border-left: 1px solid #ddd;
				}
			}
		}

		.table-bordered {
			border: 1px solid #ddd;
		}
	}
</style>
